<template>
  <div v-if="mood_id != null">
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
        <v-icon
        :color="moodColor"
        v-on="on"
        v-bind="attrs"
        >
        {{ moodIcon }}
      </v-icon>
    </template>
    
    <span>{{ moodText }}</span>
  </v-tooltip>
</div>
<span v-else>-</span>
</template>

<script>
export default {
  props: {
    mood_id: {
      type: Number,
      required: false,
    },
  },
  data: () => {
    return {
      moodItems: [
        {
          id: 1,
          icon: 'fas fa-smile-beam',
          color: 'success darken-1',
          text: 'Feliz',
        },
        { id: 2, icon: 'fas fa-smile', color: 'cyan', text: 'Pouco Feliz' },
        { id: 3, icon: 'fas fa-meh', color: 'grey', text: 'Normal' },
        {
          id: 4,
          icon: 'fas fa-frown',
          color: 'yellow darken-3',
          text: 'Pouco Infeliz',
        },
        { id: 5, icon: 'fas fa-angry', color: 'error', text: 'Infeliz' },
      ],
    }
  },
  computed: {
    moodIcon() {
      let { icon } = this.moodItems.find((el) => this.mood_id === el.id)
      return icon
    },
    moodColor() {
      let { color } = this.moodItems.find((el) => this.mood_id === el.id)
      return color
    },
    moodText() {
      let { text } = this.moodItems.find((el) => this.mood_id === el.id)
      return text
    },
  },
}
</script>
